import { useQuery } from '@tanstack/react-query';
import { Button, Card, Form, Input, InputNumber, message } from 'antd';
import { WOOD_CODE } from 'blog/wood-code';
import { isNil, isArray, values } from 'lodash-es';
import { useNavigate } from 'react-router';
import { honoClient } from './honoClient';
import { useEffect, useTransition } from 'react';
import { useTitle } from 'ahooks';

export default function CostSetting() {
	useTitle('成本设置');
	const [isPending, startTransition] = useTransition()
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const costSettingQuery = useQuery({
		queryKey: ['costSettingQuery'],
		queryFn: async () => {
			const res = await honoClient.costSettingQuery.$post({
				json: {},
			});

			return await res.json();
		},
		initialData: [],
	});

	useEffect(() => {
		const costData = costSettingQuery?.data?.sort((a, b) => {
			return isNil(a?.cost) ? -1 : 1;
		});

		startTransition(() => {
			form.setFieldsValue(costData);
		})
	}, [costSettingQuery.data, form]);


	if (costSettingQuery.isLoading) {
		return <div className='container flex justify-center'>loading</div>
	}

	return (
		<div className="grid grid-cols-1">
			<Card
				title="成本设置"
				extra={
					<Button
						onClick={() => {
							form.submit();
						}}
					>
						提交
					</Button>
				}
			>
				<Form
					form={form}
					onFinish={async (v) => {
						const res = await honoClient.setStockCost.$post({
							json: values(v).map((a) => {
								if (!isNil(a.cost)) {
									a.cost = a.cost.toString();
								}

								return a;
							}),
						});

						const opRes = await res.json();

						if (opRes.code === WOOD_CODE.OK) {
							message.success('设置成功', 2, () => {
								costSettingQuery.refetch();
							});

							return;
						}

						message.warning(
							`设置失败 code:${opRes?.code} ${opRes?.error && JSON.stringify(opRes?.error)}`,
						);
					}}
					onFinishFailed={(v) => {
						console.log('onFinishFailed', v);
					}}
				>
					<Form.Item shouldUpdate noStyle>
						{({ getFieldsValue }) => {
							const all = getFieldsValue(true);

							if (!isArray(all)) {
								return <div>loading</div>;
							}

							return all?.map((a, idx) => {
								return (
									<div
										className="flex flex-row gap-2"
										key={`${a.specifications}-${a.ack}-${idx}`}
									>
										<Form.Item
											label="specifications"
											name={[idx, 'specifications']}
										>
											<Input disabled />
										</Form.Item>

										<Form.Item label="ack" name={[idx, 'ack']}>
											<Input disabled />
										</Form.Item>

										<Form.Item label="cost" name={[idx, 'cost']}>
											<InputNumber />
										</Form.Item>
									</div>
								);
							});
						}}
					</Form.Item>
				</Form>
			</Card>
		</div>
	);
}
